/* Server.module.scss */

.container {
  display: flex;
  padding: 40px;
  font-size: x-large;
}

.container {
  /* Add styles for the container wrapping the location cards */
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.locationCard {
  /* Styles for each location card */
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
}

.locationCard h2 {
  /* Styles for the location name */
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bolder;
  text-transform: uppercase;
}

.poolList {
  /* Styles for the container wrapping pool cards within a location card */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.poolCard {
  /* Styles for each pool card */
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.poolCard h3 {
  /* Styles for the pool name */
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bolder;
  text-transform: uppercase;
}

.poolCard p {
  /* Styles for the pool status */
  margin-bottom: 5px;
  font-size: 14px;
}

/* Add specific styles for active and inactive pools */

.active {
  background-color: #c1f7d7; /* Green background for active pool */
}

.inactive {
  background-color: #f7d7d7; /* Red background for inactive pool */
}
/* Blinking light animations */
@keyframes blinkGreen {
  0% {
    background-color: green;
  }
  50% {
    background-color: green;
  }
  51% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes blinkRed {
  0% {
    background-color: red;
  }
  50% {
    background-color: red;
  }
  51% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}

/* Add blinking animation to the .statusLight element for green and red lights */
.greenLight {
  &.blinking {
    animation: blinkGreen 15s infinite;
  }
}

.redLight {
  &.blinking {
    animation: blinkRed 15s infinite;
  }
}
