body {
  margin: 0;
  padding: 0;
}

.title {
  word-break: normal;
}

.section,
.hero-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.column {
  flex-basis: unset;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-footer {
  margin-top: auto;
}

.redux-toastr .toastr .close-toastr {
  opacity: 1;
  color: #fff;
}

.redux-toastr .toastr .rrt-left-container {
  width: 75px;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
  width: 26px !important;
  height: 26px !important;
}

.redux-toastr .toastr .rrt-middle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 75px;
  min-height: 70px;
}

.error {
  color: red;
}

.error::before {
  display: inline;
  content: '⚠ ';
}

.guage-container {
  padding: 20px;
}

.notification-container {
  padding: 20px;
}

.poolTitle {
  text-align: center;
}

.each-pool-container {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}

.status-text-center {
  text-align: center;
}

.pool-container {
  position: relative;
}

.summary {
  justify-content: space-evenly;
}

.b {
  font-weight: bold;
  font-size: 18px;
}

.pool-layout {
  position: absolute;
  top: 10px;
}

.swimmers-count {
  display: flex;
  text-align: center;
}

.swimmers-icon {
  width: 100px;
  height: 100px;
}

.summary-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.total-count-container {
  flex-direction: column;
  background-color: white;
  padding: 20px;
  height: 260px;
}

.safety-container {
  width: 200px;
  padding-top: 50px;
}

.total-count-margin-left {
  margin-left: 20px;
}

.margin-bottom-total-container {
  margin-bottom: 20px;
  padding-top: 18px;
}

.swimmer-count-title {
  font-size: 30px;
  font-weight: bold;
}

.swimmer-count-sub-title {
  font-size: 14px;
}

.total-count-main-container {
  display: flex;
  justify-content: space-between;
  height: 260px;
}

.margin-left-20 {
  margin-left: 20px;
}

.review-container {
  display: grid;
  text-align: center;
  grid-template-columns: 50px 50px;
  grid-column-gap: 2rem;
}

.safety-title {
  font-size: 20px;
  font-weight: bold;
}

.main-title {
  display: flex;
}

.gauage-container {
  display: flex;
}

.guage-side-container {
  text-align: center;
  margin-top: 10px;
}

.system-dot-green {
  height: 15px;
  width: 15px;
  background-color: #5be12c;
  border-radius: 50%;
  display: inline-block;
  margin-top: 15px;
  margin-left: 10px;
}

.system-dot-red {
  height: 15px;
  width: 15px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-top: 15px;
  margin-left: 10px;
}

.dot-green {
  height: 15px;
  width: 15px;
  background-color: #5be12c;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  margin-top: 6px;
}

.dot-red {
  height: 15px;
  width: 15px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  margin-top: 6px;
}

.dot-yellow {
  height: 25px;
  width: 25px;
  background-color: #f5cd19;
  border-radius: 50%;
  display: inline-block;
}

.risk-color-container {
  margin-right: 15%;
}

.pool-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.pool-title-container {
  display: flex;
  text-align: center;
  justify-content: center;
}

.margin-l {
  margin-left: 10px;
}

.red-color {
  color: red;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1.8fr;
  grid-gap: 10px;
}

.grid-item {
  padding: 20px; /* add padding */
}

.right-border {
  border-right: 1px solid #ccc;
}

.notification-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.list-card {
  display: flex;
  flex-direction: column;
  padding: 15px 0px 15px 20px;
  height: 150px;
  width: 60%;
}

.image-card {
  display: flex;
  flex-direction: column;
  width: 40%;
}
.image-card-main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.list-number {
  font-size: 30px;
  font-weight: bold;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.flex-card {
  display: flex;
  //justify-content: space-between;
  height: fit-content;
  width: 100%;
}
